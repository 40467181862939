import {autoinject, bindable, TaskQueue} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {Questionnaire} from "./questionnaire";
import {translations} from "../../classes/translations";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {NitTools} from "../../classes/NursitTools";
import {FormBaseClass} from "../FormBaseClass";
import {QuestionnaireService} from "resources/services/QuestionnaireService";
import {fhirEnums} from "../../classes/fhir-enums";
import {Tools} from "../../classes/FhirModules/Fhir";
import {PatientService} from "../../services/PatientService";
import {IQuestionnaireListItem} from "../../classes/IQuestionnaireListItem";
import {ConfigService} from "../../services/ConfigService";
import {UserService} from "../../services/UserService";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {AnalyzeService} from "resources/services/analyzeService";
import {DialogMessages} from "resources/services/DialogMessages";
import {IdLogikService} from "resources/services/IdLogikService";
import {qChangeNotifier} from "./q-changeNotifier";
import {PermissionService} from "resources/services/PermissionService";
import {LogoutService} from "../../services/LogoutService";

const moment = require("moment");
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

@autoinject
export class QuestionnaireDialogContainer extends FormBaseClass {
    constructor(protected i18n: I18N,
        router: Router, queue: TaskQueue, notifier: PatientChangeNotifier, dialogService: DialogService,
        analyzeService: AnalyzeService,
        protected patientService: PatientService,
        protected dialogMessages: DialogMessages,
        protected idLogikService: IdLogikService,
        protected responseNotifier: qChangeNotifier,
        protected permissionService: PermissionService,
        protected logoutService : LogoutService)
    {
        super(i18n,router,queue,notifier,dialogService,analyzeService,patientService,dialogMessages,idLogikService, responseNotifier, permissionService, logoutService);
        this.responseNotifierSubScriberId = this.responseNotifier.subscribe((item) => this.checkGroupVisibilites(item));
    }

    @bindable grouplist: boolean;
    @bindable item: any[];
    // dateFormat: string = "d";
    reAssignResponses = false;
    questionnaireName = "CareITDiagnosis";
    questionnaireTitle = '';
    status: string;
    dialogService: DialogService;
    showToolbar: boolean = false;
    settings: IQuestionnaireDialogSettings = undefined;
    @bindable questionnaireClass: Questionnaire;
    maxAge: number = 9999999999;
    allowAddNew: boolean = false;

    forcedReadonly : boolean = false;
    isTrainee : boolean = false;

    async createNewDocument() {
        if (!this.questionnaire || !this.allowAddNew) return;
        let tmpNewItem = Tools.SubstituteDefaultQRSkeleton(this.patient, this.questionnaire.id, QuestionnaireResponseStatus.inProgress);
        let newItem = <any>await this.fhirService.create(tmpNewItem);
        PatientService.AddQuestionnaireResponse(this.patient, newItem, false);
        let tmp = this.patient.questionnaireResponses.filter(o => o.questionnaire && o.questionnaire.reference && o.questionnaire.reference.indexOf('/' + this.questionnaire.id) > -1);
        this.responses = tmp.sort((r1, r2) => {
            return new Date(r1.authored).valueOf() - new Date(r2.authored).valueOf();
        });

        this.selectLastResponse();
    }

    abortButtonClicked() {
        this.close(true);
    }

    saveButtonClicked() {
        if (this.readonly) {
            this.close(true);
            return;
        }

        if (this.response.status === fhirEnums.QuestionnaireResponseStatus.inProgress)
            this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
        else if (this.response.status === fhirEnums.QuestionnaireResponseStatus.completed)
            this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
        else if (this.response.status !== fhirEnums.QuestionnaireResponseStatus.amended)
            this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;

        if (this.response.meta) {
            this.response.meta.lastUpdated = new Date().toJSON();
        }

        // tripple calculation to be extremely sure even the dependend value match
        this.questionnaireClass.calculateFieldValues();
        this.questionnaireClass.calculateFieldValues();
        this.questionnaireClass.calculateFieldValues();

        this.close(false);
    }

    close(canceled: boolean) {
        let dialogController = this.dialogService.controllers[0];
        if (!dialogController) {
            this.dialogService.closeAll();
        } else {
            if (canceled) {
                this.response.item = NitTools.Clone(this.responseBackup);
                dialogController.cancel(this.response);
            } else {
                dialogController.ok(this.response);
            }
        }
    }

    debug(msg: string) {
        if (ConfigService.Debug) {
            console.debug(`[QuestionnaireDialogContainer] - ${msg}`);
        }
    }

    selectLastResponse() {
        if (this.settings.selectLastResponse === false) return;
        super.selectLastResponse();
    }

    async questionnaireClassChanged() {
        if (!this.questionnaireClass) {
            // this.debug("No questionnaireClass");
        } else {
            // this.debug("questionnaireClassChanged() triggered, calculating form values");
            await PatientItem.Load(this.settings.encounter.id)
                .catch(err => {
                    console.warn(err);
                })

            if (!this.response) {
                this.questionnaireClass.readonly = true;
            } else {
                this.questionnaireClass.preFieldCalculationFunction = this.settings.preFieldCalculationFunction;
                this.questionnaireClass.calculateFieldValues();
            }
        }
    }

    override getQuestionnaireListItem(response): IQuestionnaireListItem {
        let questionnaire = undefined;
        try {
            questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire)
        } catch {
            // noop
        }

        let r = {
            id: response.id,
            text: `${questionnaire ? (questionnaire.name || questionnaire.title) : ''} @ ${moment(response.authored).format(this.dateFormat)}`,
            date: moment(response.authored).toDate()
        };

        r.text = `#${this.responses.length + 1} ${r.text}`;

        return r;
    }

    saveText: string = undefined;
    abortText: string = undefined;
    data: any = undefined;
    showSelection: boolean = true;
    tooold: boolean = false;
    uxBody: any;
    isDebug : boolean = ConfigService.Debug;
    override async attached(): Promise<void> {
        await super.attached();
        this.uxBody.style.height = `${window.innerHeight - 165}px`;
    }

    override async activate(settings?: IQuestionnaireDialogSettings) {
        this.settings = settings;

        if (settings) {
            if (settings.showToolbar) {
                this.dateFormat = translations.translate("date_time_format_short");
            }

            if (typeof settings.selectLastResponse === "undefined") settings.selectLastResponse = true;
            this.data = settings.data;
            this.isTrainee = settings["isTrainee"] || UserService.UserRole === 'trainee';
            this.saveText = settings.saveText || this.i18n.tr("save");
            this.abortText = settings.abortText || this.i18n.tr("abort");
            this.tooold = settings.tooold === true;
            this.showSelection = typeof settings.showSelection !== "undefined" ? settings.showSelection : true;
            this.grouplist = settings.grouplist;
            if (typeof settings.forcedReadonly === 'boolean')
                this.forcedReadonly = settings.forcedReadonly;

            this.questionnaireTitle = settings.questionnaire.title || settings.questionnaire.name;
            this.dialogService = settings.dialogService;

            this.questionnaire = settings.questionnaire;
            this.status = settings.status;
            this.showToolbar = settings.showToolbar;
            this.allowAddNew = settings.allowAddNew;
            this.responseBackup = NitTools.Clone(settings.response ? settings.response.item : undefined);
            this.removeNoToolbarWindow = typeof settings.removeNoToolbarWindow !== "undefined" ? settings.removeNoToolbarWindow : true;
            this.response = settings.response;

            if (!this.questionnaire && this.response && this.response.questionnaire) {
                this.questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.response?.questionnaire);
                this.questionnaireName = this.questionnaire ? this.questionnaire.name || this.questionnaire.title : 'Questionnaire not found!';

                this.item = this.questionnaire.item;
            }
        }
    }
}

export interface IQuestionnaireDialogSettings {
    grouplist: boolean;
    response: any;
    questionnaire: any;
    encounter: any;
    tooold: boolean;
    haschanges: boolean;
    dialogService: DialogService;
    status?: string;
    showToolbar?: boolean;
    allowAddNew?: boolean;
    showSelection?: boolean;
    patientService?: PatientService;
    data?: any;
    saveText?: string;
    abortText?: string;
    selectLastResponse?: boolean;
    stati?: any;
    removeNoToolbarWindow?: boolean;
    preFieldCalculationFunction? : any;
    forcedReadonly? : boolean;
}
