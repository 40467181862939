import {autoinject, bindable} from "aurelia-framework";
import {NitTools} from "../../classes/NursitTools";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {Questionnaire, QuestionnaireResponse} from "../../classes/FhirModules/Fhir";
import {PatientService} from "resources/services/PatientService";
import {DialogMessages} from "../../services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {fhirEnums} from "../../classes/fhir-enums";
import {PatientItem} from "../../classes/Patient/PatientItem";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

const expr = require("expr-eval");

@autoinject
export class qPipe {
    encounter;
    responseItem;
    target;
    referencedItem;
    pipeValue: string;
    pipeValueDiffersFromCurrentValue: boolean = false;
    code: string;
    display: string;

    @bindable item;
    @bindable response;
    @bindable patient: PatientItem;
    @bindable encounterid: string;
    @bindable readonly: boolean;

    constructor(protected patientService: PatientService, protected dialogMessages: DialogMessages, protected i18n: I18N) {
    }

    get responseValue(): string {
        if (!this.responseItem) return;
        let curVal = QuestionnaireResponse.GetResponseItemValue(this.responseItem, "");
        return String(curVal);
    }

    set responseValue(newVal) {
        if (!newVal && this.responseItem) {
            delete this.responseItem.answer;
            return;
        }

        if (newVal && this.pipeValue)
            this.pipeValueDiffersFromCurrentValue = String(newVal) !== String(this.pipeValue);

        this.responseItem.answer = [{valueString: String(newVal)}];
    }

    solveReference(result) {
        if (result && this.target && this.target.contained
            &&
            ((result["reference"] && result["reference"].indexOf('#') === 0)
                || (typeof result["text"] === "string" && result["text"] && result["text"].indexOf('#') === 0)
                || (typeof result["text"] === "string" && result["display"] && result["display"].indexOf('#') === 0))
        ) {
            let ref: string = result["reference"] || result["text"] || result["display"];
            ref = ref.substring(1);
            let newObject = this.target.contained.find(o => o.id === ref);
            if (newObject) {
                this.referencedItem = newObject;
                result = newObject;
            }
        }

        return result;
    }

    applyPipeValue() {
        if (!this.response || !this.responseItem || !this.pipeValue || this.readonly || !this.pipeValueDiffersFromCurrentValue) return;

        let message = `Soll der aktuell eingegebene Wert: <br /><b>${this.responseValue}</b><br /><br /> durch den ermittelten Wert:<br /><b>${this.pipeValue}</b><br /><br />ersetzt werden?`;
        if (!this.responseValue && this.pipeValue) {
            message = `Soll der ermittelte Wert:<br /><br /><b>${this.pipeValue}</b><br /><br />übernommen werden?`;
        }

        this.dialogMessages.dialog(message, this.i18n.tr("confirm"), this.i18n.tr("yes"), this.i18n.tr("no"), true)
            .whenClosed(result => {
                if (!result.wasCancelled) this.responseValue = this.pipeValue;
            });

        return false;
    }

    getSubValue(object, propName) {
        let pn = propName;
        let result: any = undefined;
        if (propName.indexOf('.') > 0) {
            pn = propName.substr(0, propName.indexOf('.'));
            let isArrayPropertyName = pn.indexOf('[') > -1 && pn.indexOf(']') > -1;
            if (isArrayPropertyName) {
                let arrIdx = pn.substr(pn.indexOf('[') + 1, pn.indexOf(']') - pn.indexOf('[') - 1); // get the array index
                let arrPropertyName = pn.split('[')[0];
                result = object[arrPropertyName][arrIdx];
            } else {
                result = object[pn];
            }

            if (result) {
                if (NitTools.IsArray(result)) {
                    result = result[0];
                }

                result = this.solveReference(result);

                let pnRest = propName.substr(propName.indexOf('.') + 1);
                if (pnRest) {
                    result = this.getSubValue(result, pnRest);
                }
            }
        } else {
            result = object[propName];
            result = this.solveReference(result);
        }

        return result;
    }

    responseChanged() {
        this.update();
    }

    /**
     * Convert from a simple path like 'anamnesis.g_2.99_01' to a valid fhirPath expression in the way of anamnesis.item.where(linkId='g_2').item...
     * @param aStr the Path-Expression to convert to a clean fhirPath path
     */
    cleanPath(aStr: string): string {
        if (aStr.indexOf('|') === 0) aStr = aStr.substr(1);
        let backup = aStr ? aStr.trim() : undefined;
        if (!aStr) return backup;
        let result = aStr;
        try {
            // const regex = /(['](.*?)['])|((anamnesis\.|assessment\.)(.*?)(\.code|\.display|\.value))/gi;
            const regex = /(['](.*?)['])|((anamnesis\.|assessment\.)(.*?)(\.answer))/gi;
            let m;
            let str = aStr.trim();
            while ((m = regex.exec(str)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                if (m["0"]) {
                    let fullMatch = m["0"];
                    if (fullMatch.indexOf('\'') === -1) {
                        if (fullMatch.indexOf('where') === -1 && fullMatch.indexOf('linkId') === -1) {
                            let items = fullMatch.split('.');
                            let parts = [];
                            for (let i = 1; i < items.length - 1; i++) parts.push(items[i]);
                            let sResult = items[0];

                            for (let i = 0; i < parts.length; i++) {
                                parts[i] = `.item.where(linkId='${parts[i]}')`
                                sResult += parts[i];
                            }

                            sResult += '.' + items[items.length - 1];
                            result = result.replace(fullMatch, sResult)
                        }
                    }
                }
            }

            return result;
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
            return backup;
        }
    }

    /**
     * Takes an object or array as parameter and converts them to a meaningful string. I case of array it will be comma-seperated
     * @param arrayOrObject
     */
    makeString(arrayOrObject) {
        let sResult = undefined;
        if (arrayOrObject) {
            if (NitTools.IsArray(arrayOrObject)) {
                sResult = "";
                (<any[]>arrayOrObject).forEach(pr => {
                    sResult += sResult ? ', ' + String(pr) : String(pr);
                })
            } else {
                sResult = String(arrayOrObject);
            }
        }

        return sResult;
    }

    tryGetValue(): string {
        let path: string;
        if (!this.code) return undefined;
        let fhirObject = {
            patient: this.patient,
            encounter: this.patient?.encounter,
            anamnesis: QuestionnaireService.GetLatestResponseOfType(this.patient, QuestionnaireService.__listResult.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]),
            assessment: this.patient?.latestAssessment,
            response: this.response
        }

        try {

            // do some old-style checking. Should be extremely deprecated, but to be sure - just use it.
            /* if (!this.code) {
                this.code = this.item && this.item.initialCoding && this.item.initialCoding.code ? this.item.initialCoding.code : undefined;
            }  */

            if (!this.code) return;

            let oldPath = this.code?.trim();
            let newPath : string = undefined;
            // try to create a response-object when the format it like QuestionnaireName.linkId
            if (oldPath.indexOf('.') > -1 && (!oldPath.startsWith('fpath(') && !oldPath.startsWith('|fpath(')) && !oldPath.endsWith(')')) {
                if (oldPath.indexOf('|') === 0)
                    oldPath = oldPath.substring(1);

                newPath = oldPath.trim(); // overwrite to force fpath to take place
                oldPath = oldPath.split('.')[0]; // get the questionnaire name

                const q = QuestionnaireService.GetQuestionnaireDirect(oldPath);
                if (q) {
                    fhirObject[oldPath] = {};

                    const response = QuestionnaireService.GetLatestResponseOfName(this.patient, q.name, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
                    if (response) {
                        path = newPath;
                        for (const linkId of Questionnaire.GetAllQuestionnaireItemLinkIds(q, false)) {
                            const answers = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, false)?.answer;
                            if (NitTools.IsArray(answers)) {
                                const displays = [];
                                const values = [];
                                for (const answer of answers) {
                                    const d = QuestionnaireResponse.GetResponseAnswerDisplay(answer);
                                    const v = QuestionnaireResponse.GetResponseAnswerValue(answer);
                                    if (d) displays.push(d);
                                    if (v) values.push(v);
                                }

                                fhirObject[oldPath][linkId] = { value: values.join(','), display: displays.join(',') };
                            }
                        }
                    }
                }
            }

            let linkResult = undefined;
            try {
                if (newPath && path?.indexOf('fpath(') === -1) {
                    linkResult = this.getSubValue(fhirObject, newPath);
                }
            }
            catch (e) {
                linkResult = undefined;
            }

            if (linkResult) {
                console.warn('FastResult:', linkResult);
                return linkResult;
            }

            path = this.cleanPath(this.code);

            let parser = new expr.Parser();

            // add a new parser function which calls fhirPath, called fpath
            // (see: https://www.npmjs.com/package/expr-eval#custom-javascript-functions)
            parser.functions.fpath = (path) => {
                const fhirPathResult = fhirpath.evaluate(fhirObject, path, null, fhirpath_stu3_model);
                let result = this.makeString(fhirPathResult);
                return result;
            };

            let pathResultNew = undefined;
            if (path.indexOf('fpath(') > -1) {
                pathResultNew = parser.evaluate(path);
            } else {
                pathResultNew = fhirpath.evaluate(fhirObject, path, null, fhirpath_stu3_model);
            }

            pathResultNew = this.makeString(pathResultNew);
            if (pathResultNew) {
                pathResultNew = pathResultNew.replace(/, ,/g, ', ').replace(/  /g, ' ');
                pathResultNew = pathResultNew.trim();

                if (pathResultNew.endsWith(',')) {
                    pathResultNew = pathResultNew.substr(0, pathResultNew.length - 1);
                    if (pathResultNew) pathResultNew = pathResultNew.trim();
                }
            }

            return pathResultNew || '';
        } catch (e) {
            try {
                const result = this.getSubValue(fhirObject, path);
                return result || '';
            } catch (e2) {
                console.warn(e2.message, this.item);
                return e2.message;
            }
        }
    }

    update() {
        // many exit strategies:
        if (!this.patient || !this.item || !this.code || !this.response) return;
        this.responseItem = this.response ? Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true) : undefined;
        if (!this.responseItem) return;

        this.pipeValue = this.tryGetValue();
        if (typeof this.pipeValue !== "undefined") {
            this.pipeValueDiffersFromCurrentValue = this.responseValue && String(this.pipeValue) !== String(this.responseValue);
        }

        if (this.responseValue && this.responseValue.indexOf('|') === -1) return; // check for | too to cleanup faulty responses which contain the old pipe field from initial value
        if (this.response.status === 'completed' || this.response.status === 'amended') return;

        // not exited, obviously, so let's get the value
        if (this.responseItem) {
            this.responseItem.answer = [{valueString: this.pipeValue}];
        }

        this.display = $(`<div>${this.pipeValue || ''}</div>`)[0].innerText;

        let div = document.createElement('div');
        div.innerHTML = this.display;
        this.responseValue = div.innerText;
    }

    encounteridChanged() {
        if (this.patient && this.encounter?.id === this.encounterid) return;
        this.patient = PatientItem.SelectedPatient;
        this.encounter = this.patient?.encounter;
        this.update();
    }

    itemChanged() {
        let ext = this.item?.extension?.find(o => o.url.endsWith('questionnaire-pipe-field'));
        if (ext) {
            this.code = ext.valueString;
        } else {
            if (NitTools.IsArray(this.item?.initialCoding)) {
                this.code = this.item?.initialCoding[0];
            } else {
                this.code = this.item?.initialCoding?.code;
            }
        }

        if (this.code && this.code.indexOf('|') === -1) this.code = '|' + this.code;

        this.update();
    }
}
