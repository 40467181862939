import {Allergy, IAllergy} from "../../classes/allergy";
import {NitTools} from "../../classes/NursitTools";
import {QuestionnaireResponse} from "../../classes/FhirModules/QuestionnaireResponse";
import {autoinject} from "aurelia-framework";
import {FhirService} from "../../services/FhirService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {bindable} from "aurelia-templating";
import {I18N} from "aurelia-i18n";
import {DialogMessages} from "../../services/DialogMessages";
import {DialogService} from "aurelia-dialog";
import {allergyAdd} from "../../../views/patient/allergy/allergy-add";
import {ConfigService} from "../../services/ConfigService";

const moment = require('moment');

@autoinject
export class QAllergyList {
    @bindable patient: PatientItem;
    @bindable response;
    @bindable item;

    responseItem;
    isLoadingAllergies : boolean = false;
    allergies : IAllergy[] = [];

    constructor(protected fhirService : FhirService, protected i18n : I18N, protected dialogService : DialogService, protected dialogMessages : DialogMessages) {
    }

    patientChanged() {
        this.loadData();
    }

    itemChanged() {
        this.loadData();
    }

    responseChanged() {
        this.responseItem = undefined;
        this.loadData();
    }

    loadData() {
        this.getResponseItem();
        this.checkPatientAllergies().catch(e => console.warn(e));
    }

    getResponseItem() {
        if (!this.response || !this.item) {
            this.responseItem = undefined;
            return;
        }

        if (this.responseItem) return;

        this.responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
        if (!this.responseItem.answer)
            this.responseItem.answer = [];
    }

    async checkPatientAllergies() {
        if (!this.patient || !this.responseItem) return;

        this.allergies = [];
        this.isLoadingAllergies = true;

        // when the response has not been finalized load all the current allergies
        if (this.response?.status === 'in-progress') {
            // no allergies loaded yet? So load them now!
            if (!this.patient.allergies || this.patient.allergies.length === 0) {
                // should have been loaded into the patient item directly, but always be sure..
                await Allergy.LoadForPatient(this.patient, this.fhirService, this.i18n);
            }

            this.allergies = NitTools.Clone(this.patient.allergies);
            // now write the current allergies into the responseItem to be current:
            const oldValue = JSON.stringify(this.responseItem?.answer);
            const newAnswers = [];
            // this.responseItem.answer = [];

            for (const a of this.allergies) {
                const ans = {
                    id : a.id,
                    // valueString: `${a.text} [${a.type||'-'}, ${a.status||'-'},  ${a.verificationStatus||'-'}, ${moment(a.assertedDate).format(RuntimeInfo.DateTimeFormat)}]`,
                    valueString: `${a.assertedDate};${a.code};${a.text};${a.fhirType};${a.category};${a.criticality};${a.reaction};${a.reactionSeverity};${a.status};${a.verificationStatus}`,
                    extension: [
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-display",
                            valueString: a.text
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-category",
                            valueString: a.category
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-criticality",
                            valueString: a.criticality
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-status",
                            valueString: a.status
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-verification",
                            valueString: a.verificationStatus
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-type",
                            valueString: a.type
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-recorded",
                            valueString: a.assertedDate
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-is-active",
                            valueBoolean: a.isActive
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-code",
                            valueString: a.code
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-reaction",
                            valueString: a.reaction
                        },
                        {
                            url: "http://nursit-insitute.com/fhir/allergy-reaction-severity",
                            valueString: a.reactionSeverity
                        }
                    ]
                }

                newAnswers.push(ans);
            }

            if (JSON.stringify(newAnswers) != oldValue) {
                this.responseItem.answer = newAnswers;
                if (ConfigService.Debug)
                    console.debug("Updated Answers to:", this.responseItem);
            }
        } else // when the response has been finalized then the allergies should be contained in the answer, so grab them from there
        {
            if (this.responseItem?.answer) {
                this.allergies = Allergy.FromAnswers(this.responseItem.answer, this.i18n);
            }
        }

        this.isLoadingAllergies = false;
    }

    allergiesReadonly : boolean = false;
    popupShowing : boolean = false;
    async openAddView() {
        if (this.popupShowing) return;   // check this 1st to avoid double click displaying multiple instances

        let valueSetId : string;
        try {
            const cfg = ConfigService.GetFormSettings("allergy");
            if (!cfg) {
                throw "No Config for route \"allergy\" defined";
            }

            if (!cfg.settings) {
                throw "No Settings in config for route \"allergy\" defined";
            }

            valueSetId = cfg.settings?.valueSetId;
            if (!valueSetId) {
                throw "No ValueSetId specified in config for route \"allergy\"";
            }
        }
        catch (e) {
            this.dialogMessages.prompt(e, this.i18n.tr('error'), true);
            return;
        }

        this.popupShowing = true;
        this.dialogService.open({
            viewModel: allergyAdd,
            model: { patient: this.patient, valueSetId: valueSetId, service : this.fhirService },
            lock: true
        })
            .whenClosed(async e => {
                this.popupShowing = false;
                if (e.wasCancelled)
                    return;

                this.isLoadingAllergies = true;
                try {
                    await this.fhirService.update(e.output);
                    await Allergy.LoadForPatient(this.patient, this.fhirService, this.i18n);
                    await this.checkPatientAllergies();
                }
                catch (e) {
                    this.dialogMessages.prompt(e.message||e, this.i18n.tr("error"), true);
                }
                finally {
                    this.isLoadingAllergies = false;
                }
            })
            .catch(e => {
                console.warn(e)
                this.popupShowing = false;
                this.isLoadingAllergies = false;
            });
    }

    async askDeleteAllergy(allergy) {
        if (!allergy) {
            console.warn('No Allergy provided to delete');
            return;
        }

        const msg = this.i18n.tr('allergy_confirm_delete', allergy);
        DialogMessages.Dialog(this.dialogService, msg, this.i18n.tr('confirm'), this.i18n.tr('delete'), this.i18n.tr('abort'))
            .whenClosed(async o => {
                if (o.wasCancelled)
                    return;

                try {
                    this.isLoadingAllergies = true;
                    await this.fhirService.deleteUrl(`AllergyIntolerance/${allergy.id}`);
                    await Allergy.LoadForPatient(this.patient, this.fhirService, this.i18n);
                    await this.checkPatientAllergies();
                }
                finally {
                    this.isLoadingAllergies = false;
                }
            })
            .catch();
    }
}
